<template>
  <div class="login">
    <div class="loginBox">
      <img
        class="loginStyleImg"
        src="@/assets/image/loginStyleImg.png"
        alt=""
      />
      <div class="login-center">
        <div class="login-form">
          <p class="welcome-txt">您好，欢迎来到客户销售查询系统！</p>
          <h4 class="login-txt">登录</h4>
          <div>
            <!-- 登陆表单 -->
            <a-form :form="form" @submit="handleSubmit">
              <a-form-item>
                <template slot="label">
                  <img src="@/assets/image/loginUserIcon.png" alt="" />
                  <span>用户名</span>
                </template>
                <a-input
                  v-decorator="[
                    'userName',
                    {
                      rules: [{ required: true, message: '请输入用户名!' }],
                    },
                  ]"
                />
              </a-form-item>
              <a-form-item>
                <template slot="label">
                  <img src="@/assets/image/loginPasswordIcon.png" alt="" />
                  <span class="pass">密 码</span>
                </template>
                <a-input
                  autocomplete
                  v-decorator="[
                    'password',
                    { rules: [{ required: true, message: '请输入密码!' }] },
                  ]"
                  type="password"
                />
              </a-form-item>
              <a-form-item>
                <a-button
                  type="primary"
                  class="submitBtn"
                  :loading="loading"
                  html-type="submit"
                  >{{ loading ? "登录中..." : "进入" }}</a-button
                >
              </a-form-item>
            </a-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      form: this.$form.createForm(this, { name: "coordinated" }),
      loading: false,
    };
  },
  // 事件处理器
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.loading = true;
          this.$ajax({
            url: "/hxclass-pc/user/channel/login",
            method: "POST",
            params: {
              password: values.password,
              username: values.userName,
            },
          }).then((res) => {
            if (res.code == 200 && res.success) {
              sessionStorage.setItem('orderToken', res.data)
              this.$message.success("登录成功！");
              this.$router.replace("/order/customerOrder");
            } else {
              this.loading = false;
              this.$message.error(res.message);
            }
          });
        }
      });
    },
    formIsPass() {
      const form = this.form.getFieldsValue();
      if (form.userName && form.password) {
        return true;
      }
      return false;
    },
  },
  // 生命周期-实例创建完成后调用
  created() {},
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.login {
  position: absolute;
  z-index: 100;
  width: 100%;
  .loginBox {
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;

    .Entry-name {
      position: absolute;
      left: 47px;
      top: 20px;
      display: flex;
      align-items: center;
      .t-h3 {
        font-size: 16px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 24px;
      }
      .t-i {
        margin: 0 10px;
        font-style: normal;
        color: rgba(255, 255, 255, 0.53);
      }
      .t-span {
        font-size: 12px;
        font-family: PingFang SC-Light, PingFang SC;
        font-weight: 300;
        color: rgba(255, 255, 255, 0.8);
        line-height: 18px;
      }
    }
    .loginStyleImg {
      width: 100%;
      height: 100%;
    }
    .login-center {
      position: absolute;
      width: 1135px;
      height: 427px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background-image: url(~@/assets/image/login_info.png);
      background-size: 100% 100%;
      overflow: hidden;
      .login-form {
        float: right;
        width: 460px;
        height: 100%;
        background: #ffffff;
        border-radius: 20px;
        padding: 20px 30px 40px 30px;
        .welcome-txt {
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 21px;
        }
        .login-txt {
          text-align: center;
          margin-top: 38px;
          font-size: 20px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 30px;
        }
        /deep/.ant-form-item-label > label {
          display: flex;
          align-items: center;
          img {
            width: 11px;
            height: 11px;
            margin-right: 3px;
          }
          span {
            font-size: 12px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 18px;
            margin-left: 3px;
          }
          .pass {
            word-spacing: 0.5em;
          }
        }
        /deep/.ant-form-item-required::before {
          content: "";
        }
        /deep/.ant-form-item-label > label::after {
          content: "";
        }
        /deep/ .ant-form-explain {
          font-size: 12px;
        }
        /deep/ .ant-input {
          outline: none;
          border: none;
          border-radius: 0;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          &:focus {
            outline: none;
            box-shadow: none;
          }
        }
        .submitBtn {
          margin-top: 40px;
          width: 100%;
          height: 52px;
          background: #15b7dd;
          border-radius: 10px;
          font-weight: 500;
          font-size: 16px;
          border: none;
        }
      }
    }
  }
}
</style>
